/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the NewSsoCredential interface.
 */
export function instanceOfNewSsoCredential(value) {
    if (!('email' in value) || value['email'] === undefined)
        return false;
    return true;
}
export function NewSsoCredentialFromJSON(json) {
    return NewSsoCredentialFromJSONTyped(json, false);
}
export function NewSsoCredentialFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'provider': json['provider'] == null ? undefined : json['provider'],
        'email': json['email'],
    };
}
export function NewSsoCredentialToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'provider': value['provider'],
        'email': value['email'],
    };
}
