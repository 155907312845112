/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SessionSnapshotFromJSON, SessionSnapshotToJSON, } from './SessionSnapshot';
/**
 * Check if a given object implements the PaginatedResponseSessionSnapshot interface.
 */
export function instanceOfPaginatedResponseSessionSnapshot(value) {
    if (!('items' in value) || value['items'] === undefined)
        return false;
    if (!('offset' in value) || value['offset'] === undefined)
        return false;
    if (!('total' in value) || value['total'] === undefined)
        return false;
    return true;
}
export function PaginatedResponseSessionSnapshotFromJSON(json) {
    return PaginatedResponseSessionSnapshotFromJSONTyped(json, false);
}
export function PaginatedResponseSessionSnapshotFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'items': (json['items'].map(SessionSnapshotFromJSON)),
        'offset': json['offset'],
        'total': json['total'],
    };
}
export function PaginatedResponseSessionSnapshotToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'items': (value['items'].map(SessionSnapshotToJSON)),
        'offset': value['offset'],
        'total': value['total'],
    };
}
