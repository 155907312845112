/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TargetOptionsFromJSON, TargetOptionsToJSON, } from './TargetOptions';
/**
 * Check if a given object implements the Target interface.
 */
export function instanceOfTarget(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('allowRoles' in value) || value['allowRoles'] === undefined)
        return false;
    if (!('options' in value) || value['options'] === undefined)
        return false;
    return true;
}
export function TargetFromJSON(json) {
    return TargetFromJSONTyped(json, false);
}
export function TargetFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'allowRoles': json['allow_roles'],
        'options': TargetOptionsFromJSON(json['options']),
    };
}
export function TargetToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'name': value['name'],
        'allow_roles': value['allowRoles'],
        'options': TargetOptionsToJSON(value['options']),
    };
}
