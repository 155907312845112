/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const TargetOptionsTargetWebAdminOptionsKindEnum = {
    WebAdmin: 'WebAdmin'
};
/**
 * Check if a given object implements the TargetOptionsTargetWebAdminOptions interface.
 */
export function instanceOfTargetOptionsTargetWebAdminOptions(value) {
    if (!('kind' in value) || value['kind'] === undefined)
        return false;
    return true;
}
export function TargetOptionsTargetWebAdminOptionsFromJSON(json) {
    return TargetOptionsTargetWebAdminOptionsFromJSONTyped(json, false);
}
export function TargetOptionsTargetWebAdminOptionsFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'kind': json['kind'],
    };
}
export function TargetOptionsTargetWebAdminOptionsToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'kind': value['kind'],
    };
}
