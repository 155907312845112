/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 *
 * @export
 */
export const TlsMode = {
    Disabled: 'Disabled',
    Preferred: 'Preferred',
    Required: 'Required'
};
export function instanceOfTlsMode(value) {
    for (const key in TlsMode) {
        if (Object.prototype.hasOwnProperty.call(TlsMode, key)) {
            if (TlsMode[key] === value) {
                return true;
            }
        }
    }
    return false;
}
export function TlsModeFromJSON(json) {
    return TlsModeFromJSONTyped(json, false);
}
export function TlsModeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function TlsModeToJSON(value) {
    return value;
}
