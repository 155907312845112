/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TicketFromJSON, TicketToJSON, } from './Ticket';
/**
 * Check if a given object implements the TicketAndSecret interface.
 */
export function instanceOfTicketAndSecret(value) {
    if (!('ticket' in value) || value['ticket'] === undefined)
        return false;
    if (!('secret' in value) || value['secret'] === undefined)
        return false;
    return true;
}
export function TicketAndSecretFromJSON(json) {
    return TicketAndSecretFromJSONTyped(json, false);
}
export function TicketAndSecretFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'ticket': TicketFromJSON(json['ticket']),
        'secret': json['secret'],
    };
}
export function TicketAndSecretToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'ticket': TicketToJSON(value['ticket']),
        'secret': value['secret'],
    };
}
