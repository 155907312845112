/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TlsFromJSON, TlsToJSON, } from './Tls';
/**
 * @export
 */
export const TargetOptionsTargetPostgresOptionsKindEnum = {
    Postgres: 'Postgres'
};
/**
 * Check if a given object implements the TargetOptionsTargetPostgresOptions interface.
 */
export function instanceOfTargetOptionsTargetPostgresOptions(value) {
    if (!('host' in value) || value['host'] === undefined)
        return false;
    if (!('port' in value) || value['port'] === undefined)
        return false;
    if (!('username' in value) || value['username'] === undefined)
        return false;
    if (!('tls' in value) || value['tls'] === undefined)
        return false;
    if (!('kind' in value) || value['kind'] === undefined)
        return false;
    return true;
}
export function TargetOptionsTargetPostgresOptionsFromJSON(json) {
    return TargetOptionsTargetPostgresOptionsFromJSONTyped(json, false);
}
export function TargetOptionsTargetPostgresOptionsFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'host': json['host'],
        'port': json['port'],
        'username': json['username'],
        'password': json['password'] == null ? undefined : json['password'],
        'tls': TlsFromJSON(json['tls']),
        'kind': json['kind'],
    };
}
export function TargetOptionsTargetPostgresOptionsToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'host': value['host'],
        'port': value['port'],
        'username': value['username'],
        'password': value['password'],
        'tls': TlsToJSON(value['tls']),
        'kind': value['kind'],
    };
}
