/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SSHTargetAuthSshTargetPasswordAuthFromJSONTyped, SSHTargetAuthSshTargetPasswordAuthToJSON, } from './SSHTargetAuthSshTargetPasswordAuth';
import { SSHTargetAuthSshTargetPublicKeyAuthFromJSONTyped, SSHTargetAuthSshTargetPublicKeyAuthToJSON, } from './SSHTargetAuthSshTargetPublicKeyAuth';
export function SSHTargetAuthFromJSON(json) {
    return SSHTargetAuthFromJSONTyped(json, false);
}
export function SSHTargetAuthFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    switch (json['kind']) {
        case 'Password':
            return Object.assign({}, SSHTargetAuthSshTargetPasswordAuthFromJSONTyped(json, true), { kind: 'Password' });
        case 'PublicKey':
            return Object.assign({}, SSHTargetAuthSshTargetPublicKeyAuthFromJSONTyped(json, true), { kind: 'PublicKey' });
        default:
            throw new Error(`No variant of SSHTargetAuth exists with 'kind=${json['kind']}'`);
    }
}
export function SSHTargetAuthToJSON(value) {
    if (value == null) {
        return value;
    }
    switch (value['kind']) {
        case 'Password':
            return SSHTargetAuthSshTargetPasswordAuthToJSON(value);
        case 'PublicKey':
            return SSHTargetAuthSshTargetPublicKeyAuthToJSON(value);
        default:
            throw new Error(`No variant of SSHTargetAuth exists with 'kind=${value['kind']}'`);
    }
}
