/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the CreateTicketRequest interface.
 */
export function instanceOfCreateTicketRequest(value) {
    if (!('username' in value) || value['username'] === undefined)
        return false;
    if (!('targetName' in value) || value['targetName'] === undefined)
        return false;
    return true;
}
export function CreateTicketRequestFromJSON(json) {
    return CreateTicketRequestFromJSONTyped(json, false);
}
export function CreateTicketRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'username': json['username'],
        'targetName': json['target_name'],
        'expiry': json['expiry'] == null ? undefined : (new Date(json['expiry'])),
        'numberOfUses': json['number_of_uses'] == null ? undefined : json['number_of_uses'],
    };
}
export function CreateTicketRequestToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'username': value['username'],
        'target_name': value['targetName'],
        'expiry': value['expiry'] == null ? undefined : ((value['expiry']).toISOString()),
        'number_of_uses': value['numberOfUses'],
    };
}
