/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TargetOptionsTargetHTTPOptionsFromJSONTyped, TargetOptionsTargetHTTPOptionsToJSON, } from './TargetOptionsTargetHTTPOptions';
import { TargetOptionsTargetMySqlOptionsFromJSONTyped, TargetOptionsTargetMySqlOptionsToJSON, } from './TargetOptionsTargetMySqlOptions';
import { TargetOptionsTargetPostgresOptionsFromJSONTyped, TargetOptionsTargetPostgresOptionsToJSON, } from './TargetOptionsTargetPostgresOptions';
import { TargetOptionsTargetSSHOptionsFromJSONTyped, TargetOptionsTargetSSHOptionsToJSON, } from './TargetOptionsTargetSSHOptions';
import { TargetOptionsTargetWebAdminOptionsFromJSONTyped, TargetOptionsTargetWebAdminOptionsToJSON, } from './TargetOptionsTargetWebAdminOptions';
export function TargetOptionsFromJSON(json) {
    return TargetOptionsFromJSONTyped(json, false);
}
export function TargetOptionsFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    switch (json['kind']) {
        case 'Http':
            return Object.assign({}, TargetOptionsTargetHTTPOptionsFromJSONTyped(json, true), { kind: 'Http' });
        case 'MySql':
            return Object.assign({}, TargetOptionsTargetMySqlOptionsFromJSONTyped(json, true), { kind: 'MySql' });
        case 'Postgres':
            return Object.assign({}, TargetOptionsTargetPostgresOptionsFromJSONTyped(json, true), { kind: 'Postgres' });
        case 'Ssh':
            return Object.assign({}, TargetOptionsTargetSSHOptionsFromJSONTyped(json, true), { kind: 'Ssh' });
        case 'WebAdmin':
            return Object.assign({}, TargetOptionsTargetWebAdminOptionsFromJSONTyped(json, true), { kind: 'WebAdmin' });
        default:
            throw new Error(`No variant of TargetOptions exists with 'kind=${json['kind']}'`);
    }
}
export function TargetOptionsToJSON(value) {
    if (value == null) {
        return value;
    }
    switch (value['kind']) {
        case 'Http':
            return TargetOptionsTargetHTTPOptionsToJSON(value);
        case 'MySql':
            return TargetOptionsTargetMySqlOptionsToJSON(value);
        case 'Postgres':
            return TargetOptionsTargetPostgresOptionsToJSON(value);
        case 'Ssh':
            return TargetOptionsTargetSSHOptionsToJSON(value);
        case 'WebAdmin':
            return TargetOptionsTargetWebAdminOptionsToJSON(value);
        default:
            throw new Error(`No variant of TargetOptions exists with 'kind=${value['kind']}'`);
    }
}
