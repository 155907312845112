/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ParameterUpdate interface.
 */
export function instanceOfParameterUpdate(value) {
    return true;
}
export function ParameterUpdateFromJSON(json) {
    return ParameterUpdateFromJSONTyped(json, false);
}
export function ParameterUpdateFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'allowOwnCredentialManagement': json['allow_own_credential_management'] == null ? undefined : json['allow_own_credential_management'],
    };
}
export function ParameterUpdateToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'allow_own_credential_management': value['allowOwnCredentialManagement'],
    };
}
