/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RecordingKindFromJSON, RecordingKindToJSON, } from './RecordingKind';
/**
 * Check if a given object implements the Recording interface.
 */
export function instanceOfRecording(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('started' in value) || value['started'] === undefined)
        return false;
    if (!('sessionId' in value) || value['sessionId'] === undefined)
        return false;
    if (!('kind' in value) || value['kind'] === undefined)
        return false;
    return true;
}
export function RecordingFromJSON(json) {
    return RecordingFromJSONTyped(json, false);
}
export function RecordingFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'started': (new Date(json['started'])),
        'ended': json['ended'] == null ? undefined : (new Date(json['ended'])),
        'sessionId': json['session_id'],
        'kind': RecordingKindFromJSON(json['kind']),
    };
}
export function RecordingToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'name': value['name'],
        'started': ((value['started']).toISOString()),
        'ended': value['ended'] == null ? undefined : ((value['ended']).toISOString()),
        'session_id': value['sessionId'],
        'kind': RecordingKindToJSON(value['kind']),
    };
}
