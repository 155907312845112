/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the SSHKnownHost interface.
 */
export function instanceOfSSHKnownHost(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('host' in value) || value['host'] === undefined)
        return false;
    if (!('port' in value) || value['port'] === undefined)
        return false;
    if (!('keyType' in value) || value['keyType'] === undefined)
        return false;
    if (!('keyBase64' in value) || value['keyBase64'] === undefined)
        return false;
    return true;
}
export function SSHKnownHostFromJSON(json) {
    return SSHKnownHostFromJSONTyped(json, false);
}
export function SSHKnownHostFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'host': json['host'],
        'port': json['port'],
        'keyType': json['key_type'],
        'keyBase64': json['key_base64'],
    };
}
export function SSHKnownHostToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'host': value['host'],
        'port': value['port'],
        'key_type': value['keyType'],
        'key_base64': value['keyBase64'],
    };
}
