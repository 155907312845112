/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the NewPublicKeyCredential interface.
 */
export function instanceOfNewPublicKeyCredential(value) {
    if (!('label' in value) || value['label'] === undefined)
        return false;
    if (!('opensshPublicKey' in value) || value['opensshPublicKey'] === undefined)
        return false;
    return true;
}
export function NewPublicKeyCredentialFromJSON(json) {
    return NewPublicKeyCredentialFromJSONTyped(json, false);
}
export function NewPublicKeyCredentialFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'label': json['label'],
        'opensshPublicKey': json['openssh_public_key'],
    };
}
export function NewPublicKeyCredentialToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'label': value['label'],
        'openssh_public_key': value['opensshPublicKey'],
    };
}
