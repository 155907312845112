/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the LogEntry interface.
 */
export function instanceOfLogEntry(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('text' in value) || value['text'] === undefined)
        return false;
    if (!('values' in value) || value['values'] === undefined)
        return false;
    if (!('timestamp' in value) || value['timestamp'] === undefined)
        return false;
    if (!('sessionId' in value) || value['sessionId'] === undefined)
        return false;
    return true;
}
export function LogEntryFromJSON(json) {
    return LogEntryFromJSONTyped(json, false);
}
export function LogEntryFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'text': json['text'],
        'values': json['values'],
        'timestamp': (new Date(json['timestamp'])),
        'sessionId': json['session_id'],
        'username': json['username'] == null ? undefined : json['username'],
    };
}
export function LogEntryToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'text': value['text'],
        'values': value['values'],
        'timestamp': ((value['timestamp']).toISOString()),
        'session_id': value['sessionId'],
        'username': value['username'],
    };
}
