/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SSHTargetAuthFromJSON, SSHTargetAuthToJSON, } from './SSHTargetAuth';
/**
 * @export
 */
export const TargetOptionsTargetSSHOptionsKindEnum = {
    Ssh: 'Ssh'
};
/**
 * Check if a given object implements the TargetOptionsTargetSSHOptions interface.
 */
export function instanceOfTargetOptionsTargetSSHOptions(value) {
    if (!('host' in value) || value['host'] === undefined)
        return false;
    if (!('port' in value) || value['port'] === undefined)
        return false;
    if (!('username' in value) || value['username'] === undefined)
        return false;
    if (!('auth' in value) || value['auth'] === undefined)
        return false;
    if (!('kind' in value) || value['kind'] === undefined)
        return false;
    return true;
}
export function TargetOptionsTargetSSHOptionsFromJSON(json) {
    return TargetOptionsTargetSSHOptionsFromJSONTyped(json, false);
}
export function TargetOptionsTargetSSHOptionsFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'host': json['host'],
        'port': json['port'],
        'username': json['username'],
        'allowInsecureAlgos': json['allow_insecure_algos'] == null ? undefined : json['allow_insecure_algos'],
        'auth': SSHTargetAuthFromJSON(json['auth']),
        'kind': json['kind'],
    };
}
export function TargetOptionsTargetSSHOptionsToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'host': value['host'],
        'port': value['port'],
        'username': value['username'],
        'allow_insecure_algos': value['allowInsecureAlgos'],
        'auth': SSHTargetAuthToJSON(value['auth']),
        'kind': value['kind'],
    };
}
