/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TlsFromJSON, TlsToJSON, } from './Tls';
/**
 * @export
 */
export const TargetOptionsTargetHTTPOptionsKindEnum = {
    Http: 'Http'
};
/**
 * Check if a given object implements the TargetOptionsTargetHTTPOptions interface.
 */
export function instanceOfTargetOptionsTargetHTTPOptions(value) {
    if (!('url' in value) || value['url'] === undefined)
        return false;
    if (!('tls' in value) || value['tls'] === undefined)
        return false;
    if (!('kind' in value) || value['kind'] === undefined)
        return false;
    return true;
}
export function TargetOptionsTargetHTTPOptionsFromJSON(json) {
    return TargetOptionsTargetHTTPOptionsFromJSONTyped(json, false);
}
export function TargetOptionsTargetHTTPOptionsFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'url': json['url'],
        'tls': TlsFromJSON(json['tls']),
        'headers': json['headers'] == null ? undefined : json['headers'],
        'externalHost': json['external_host'] == null ? undefined : json['external_host'],
        'kind': json['kind'],
    };
}
export function TargetOptionsTargetHTTPOptionsToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'url': value['url'],
        'tls': TlsToJSON(value['tls']),
        'headers': value['headers'],
        'external_host': value['externalHost'],
        'kind': value['kind'],
    };
}
