/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the NewOtpCredential interface.
 */
export function instanceOfNewOtpCredential(value) {
    if (!('secretKey' in value) || value['secretKey'] === undefined)
        return false;
    return true;
}
export function NewOtpCredentialFromJSON(json) {
    return NewOtpCredentialFromJSONTyped(json, false);
}
export function NewOtpCredentialFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'secretKey': json['secret_key'],
    };
}
export function NewOtpCredentialToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'secret_key': value['secretKey'],
    };
}
