/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TlsModeFromJSON, TlsModeToJSON, } from './TlsMode';
/**
 * Check if a given object implements the Tls interface.
 */
export function instanceOfTls(value) {
    if (!('mode' in value) || value['mode'] === undefined)
        return false;
    if (!('verify' in value) || value['verify'] === undefined)
        return false;
    return true;
}
export function TlsFromJSON(json) {
    return TlsFromJSONTyped(json, false);
}
export function TlsFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'mode': TlsModeFromJSON(json['mode']),
        'verify': json['verify'],
    };
}
export function TlsToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'mode': TlsModeToJSON(value['mode']),
        'verify': value['verify'],
    };
}
