/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the NewPasswordCredential interface.
 */
export function instanceOfNewPasswordCredential(value) {
    if (!('password' in value) || value['password'] === undefined)
        return false;
    return true;
}
export function NewPasswordCredentialFromJSON(json) {
    return NewPasswordCredentialFromJSONTyped(json, false);
}
export function NewPasswordCredentialFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'password': json['password'],
    };
}
export function NewPasswordCredentialToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'password': value['password'],
    };
}
