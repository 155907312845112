/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime';
import { CreateTicketRequestToJSON, CreateUserRequestToJSON, ExistingOtpCredentialFromJSON, ExistingPasswordCredentialFromJSON, ExistingPublicKeyCredentialFromJSON, ExistingSsoCredentialFromJSON, GetLogsRequestToJSON, LogEntryFromJSON, NewOtpCredentialToJSON, NewPasswordCredentialToJSON, NewPublicKeyCredentialToJSON, NewSsoCredentialToJSON, PaginatedResponseSessionSnapshotFromJSON, ParameterUpdateToJSON, ParameterValuesFromJSON, RecordingFromJSON, RoleFromJSON, RoleDataRequestToJSON, SSHKeyFromJSON, SSHKnownHostFromJSON, SessionSnapshotFromJSON, TargetFromJSON, TargetDataRequestToJSON, TicketFromJSON, TicketAndSecretFromJSON, UserFromJSON, UserDataRequestToJSON, } from '../models/index';
/**
 *
 */
export class DefaultApi extends runtime.BaseAPI {
    /**
     */
    async addTargetRoleRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling addTargetRole().');
        }
        if (requestParameters['roleId'] == null) {
            throw new runtime.RequiredError('roleId', 'Required parameter "roleId" was null or undefined when calling addTargetRole().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/targets/{id}/roles/{role_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"role_id"}}`, encodeURIComponent(String(requestParameters['roleId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async addTargetRole(requestParameters, initOverrides) {
        await this.addTargetRoleRaw(requestParameters, initOverrides);
    }
    /**
     */
    async addUserRoleRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling addUserRole().');
        }
        if (requestParameters['roleId'] == null) {
            throw new runtime.RequiredError('roleId', 'Required parameter "roleId" was null or undefined when calling addUserRole().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/users/{id}/roles/{role_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"role_id"}}`, encodeURIComponent(String(requestParameters['roleId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async addUserRole(requestParameters, initOverrides) {
        await this.addUserRoleRaw(requestParameters, initOverrides);
    }
    /**
     */
    async closeAllSessionsRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/sessions`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async closeAllSessions(initOverrides) {
        await this.closeAllSessionsRaw(initOverrides);
    }
    /**
     */
    async closeSessionRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling closeSession().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/sessions/{id}/close`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async closeSession(requestParameters, initOverrides) {
        await this.closeSessionRaw(requestParameters, initOverrides);
    }
    /**
     */
    async createOtpCredentialRaw(requestParameters, initOverrides) {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError('userId', 'Required parameter "userId" was null or undefined when calling createOtpCredential().');
        }
        if (requestParameters['newOtpCredential'] == null) {
            throw new runtime.RequiredError('newOtpCredential', 'Required parameter "newOtpCredential" was null or undefined when calling createOtpCredential().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json; charset=utf-8';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/users/{user_id}/credentials/otp`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewOtpCredentialToJSON(requestParameters['newOtpCredential']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ExistingOtpCredentialFromJSON(jsonValue));
    }
    /**
     */
    async createOtpCredential(requestParameters, initOverrides) {
        const response = await this.createOtpCredentialRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async createPasswordCredentialRaw(requestParameters, initOverrides) {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError('userId', 'Required parameter "userId" was null or undefined when calling createPasswordCredential().');
        }
        if (requestParameters['newPasswordCredential'] == null) {
            throw new runtime.RequiredError('newPasswordCredential', 'Required parameter "newPasswordCredential" was null or undefined when calling createPasswordCredential().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json; charset=utf-8';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/users/{user_id}/credentials/passwords`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewPasswordCredentialToJSON(requestParameters['newPasswordCredential']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ExistingPasswordCredentialFromJSON(jsonValue));
    }
    /**
     */
    async createPasswordCredential(requestParameters, initOverrides) {
        const response = await this.createPasswordCredentialRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async createPublicKeyCredentialRaw(requestParameters, initOverrides) {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError('userId', 'Required parameter "userId" was null or undefined when calling createPublicKeyCredential().');
        }
        if (requestParameters['newPublicKeyCredential'] == null) {
            throw new runtime.RequiredError('newPublicKeyCredential', 'Required parameter "newPublicKeyCredential" was null or undefined when calling createPublicKeyCredential().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json; charset=utf-8';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/users/{user_id}/credentials/public-keys`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewPublicKeyCredentialToJSON(requestParameters['newPublicKeyCredential']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ExistingPublicKeyCredentialFromJSON(jsonValue));
    }
    /**
     */
    async createPublicKeyCredential(requestParameters, initOverrides) {
        const response = await this.createPublicKeyCredentialRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async createRoleRaw(requestParameters, initOverrides) {
        if (requestParameters['roleDataRequest'] == null) {
            throw new runtime.RequiredError('roleDataRequest', 'Required parameter "roleDataRequest" was null or undefined when calling createRole().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json; charset=utf-8';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/roles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoleDataRequestToJSON(requestParameters['roleDataRequest']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => RoleFromJSON(jsonValue));
    }
    /**
     */
    async createRole(requestParameters, initOverrides) {
        const response = await this.createRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async createSsoCredentialRaw(requestParameters, initOverrides) {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError('userId', 'Required parameter "userId" was null or undefined when calling createSsoCredential().');
        }
        if (requestParameters['newSsoCredential'] == null) {
            throw new runtime.RequiredError('newSsoCredential', 'Required parameter "newSsoCredential" was null or undefined when calling createSsoCredential().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json; charset=utf-8';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/users/{user_id}/credentials/sso`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NewSsoCredentialToJSON(requestParameters['newSsoCredential']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ExistingSsoCredentialFromJSON(jsonValue));
    }
    /**
     */
    async createSsoCredential(requestParameters, initOverrides) {
        const response = await this.createSsoCredentialRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async createTargetRaw(requestParameters, initOverrides) {
        if (requestParameters['targetDataRequest'] == null) {
            throw new runtime.RequiredError('targetDataRequest', 'Required parameter "targetDataRequest" was null or undefined when calling createTarget().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json; charset=utf-8';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/targets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TargetDataRequestToJSON(requestParameters['targetDataRequest']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TargetFromJSON(jsonValue));
    }
    /**
     */
    async createTarget(requestParameters, initOverrides) {
        const response = await this.createTargetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async createTicketRaw(requestParameters, initOverrides) {
        if (requestParameters['createTicketRequest'] == null) {
            throw new runtime.RequiredError('createTicketRequest', 'Required parameter "createTicketRequest" was null or undefined when calling createTicket().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json; charset=utf-8';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/tickets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTicketRequestToJSON(requestParameters['createTicketRequest']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TicketAndSecretFromJSON(jsonValue));
    }
    /**
     */
    async createTicket(requestParameters, initOverrides) {
        const response = await this.createTicketRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async createUserRaw(requestParameters, initOverrides) {
        if (requestParameters['createUserRequest'] == null) {
            throw new runtime.RequiredError('createUserRequest', 'Required parameter "createUserRequest" was null or undefined when calling createUser().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json; charset=utf-8';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateUserRequestToJSON(requestParameters['createUserRequest']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }
    /**
     */
    async createUser(requestParameters, initOverrides) {
        const response = await this.createUserRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async deleteOtpCredentialRaw(requestParameters, initOverrides) {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError('userId', 'Required parameter "userId" was null or undefined when calling deleteOtpCredential().');
        }
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deleteOtpCredential().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/users/{user_id}/credentials/otp/{id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deleteOtpCredential(requestParameters, initOverrides) {
        await this.deleteOtpCredentialRaw(requestParameters, initOverrides);
    }
    /**
     */
    async deletePasswordCredentialRaw(requestParameters, initOverrides) {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError('userId', 'Required parameter "userId" was null or undefined when calling deletePasswordCredential().');
        }
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deletePasswordCredential().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/users/{user_id}/credentials/passwords/{id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deletePasswordCredential(requestParameters, initOverrides) {
        await this.deletePasswordCredentialRaw(requestParameters, initOverrides);
    }
    /**
     */
    async deletePublicKeyCredentialRaw(requestParameters, initOverrides) {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError('userId', 'Required parameter "userId" was null or undefined when calling deletePublicKeyCredential().');
        }
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deletePublicKeyCredential().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/users/{user_id}/credentials/public-keys/{id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deletePublicKeyCredential(requestParameters, initOverrides) {
        await this.deletePublicKeyCredentialRaw(requestParameters, initOverrides);
    }
    /**
     */
    async deleteRoleRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deleteRole().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/role/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deleteRole(requestParameters, initOverrides) {
        await this.deleteRoleRaw(requestParameters, initOverrides);
    }
    /**
     */
    async deleteSshKnownHostRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deleteSshKnownHost().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/ssh/known-hosts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deleteSshKnownHost(requestParameters, initOverrides) {
        await this.deleteSshKnownHostRaw(requestParameters, initOverrides);
    }
    /**
     */
    async deleteSsoCredentialRaw(requestParameters, initOverrides) {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError('userId', 'Required parameter "userId" was null or undefined when calling deleteSsoCredential().');
        }
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deleteSsoCredential().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/users/{user_id}/credentials/sso/{id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deleteSsoCredential(requestParameters, initOverrides) {
        await this.deleteSsoCredentialRaw(requestParameters, initOverrides);
    }
    /**
     */
    async deleteTargetRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deleteTarget().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/targets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deleteTarget(requestParameters, initOverrides) {
        await this.deleteTargetRaw(requestParameters, initOverrides);
    }
    /**
     */
    async deleteTargetRoleRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deleteTargetRole().');
        }
        if (requestParameters['roleId'] == null) {
            throw new runtime.RequiredError('roleId', 'Required parameter "roleId" was null or undefined when calling deleteTargetRole().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/targets/{id}/roles/{role_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"role_id"}}`, encodeURIComponent(String(requestParameters['roleId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deleteTargetRole(requestParameters, initOverrides) {
        await this.deleteTargetRoleRaw(requestParameters, initOverrides);
    }
    /**
     */
    async deleteTicketRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deleteTicket().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/tickets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deleteTicket(requestParameters, initOverrides) {
        await this.deleteTicketRaw(requestParameters, initOverrides);
    }
    /**
     */
    async deleteUserRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deleteUser().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deleteUser(requestParameters, initOverrides) {
        await this.deleteUserRaw(requestParameters, initOverrides);
    }
    /**
     */
    async deleteUserRoleRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deleteUserRole().');
        }
        if (requestParameters['roleId'] == null) {
            throw new runtime.RequiredError('roleId', 'Required parameter "roleId" was null or undefined when calling deleteUserRole().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/users/{id}/roles/{role_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))).replace(`{${"role_id"}}`, encodeURIComponent(String(requestParameters['roleId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async deleteUserRole(requestParameters, initOverrides) {
        await this.deleteUserRoleRaw(requestParameters, initOverrides);
    }
    /**
     */
    async getLogsRaw(requestParameters, initOverrides) {
        if (requestParameters['getLogsRequest'] == null) {
            throw new runtime.RequiredError('getLogsRequest', 'Required parameter "getLogsRequest" was null or undefined when calling getLogs().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json; charset=utf-8';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/logs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GetLogsRequestToJSON(requestParameters['getLogsRequest']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LogEntryFromJSON));
    }
    /**
     */
    async getLogs(requestParameters, initOverrides) {
        const response = await this.getLogsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getOtpCredentialsRaw(requestParameters, initOverrides) {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError('userId', 'Required parameter "userId" was null or undefined when calling getOtpCredentials().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/users/{user_id}/credentials/otp`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExistingOtpCredentialFromJSON));
    }
    /**
     */
    async getOtpCredentials(requestParameters, initOverrides) {
        const response = await this.getOtpCredentialsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getParametersRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/parameters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ParameterValuesFromJSON(jsonValue));
    }
    /**
     */
    async getParameters(initOverrides) {
        const response = await this.getParametersRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getPasswordCredentialsRaw(requestParameters, initOverrides) {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError('userId', 'Required parameter "userId" was null or undefined when calling getPasswordCredentials().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/users/{user_id}/credentials/passwords`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExistingPasswordCredentialFromJSON));
    }
    /**
     */
    async getPasswordCredentials(requestParameters, initOverrides) {
        const response = await this.getPasswordCredentialsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getPublicKeyCredentialsRaw(requestParameters, initOverrides) {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError('userId', 'Required parameter "userId" was null or undefined when calling getPublicKeyCredentials().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/users/{user_id}/credentials/public-keys`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExistingPublicKeyCredentialFromJSON));
    }
    /**
     */
    async getPublicKeyCredentials(requestParameters, initOverrides) {
        const response = await this.getPublicKeyCredentialsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getRecordingRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getRecording().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/recordings/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => RecordingFromJSON(jsonValue));
    }
    /**
     */
    async getRecording(requestParameters, initOverrides) {
        const response = await this.getRecordingRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getRoleRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getRole().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/role/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => RoleFromJSON(jsonValue));
    }
    /**
     */
    async getRole(requestParameters, initOverrides) {
        const response = await this.getRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getRolesRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleFromJSON));
    }
    /**
     */
    async getRoles(requestParameters = {}, initOverrides) {
        const response = await this.getRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getSessionRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getSession().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/sessions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SessionSnapshotFromJSON(jsonValue));
    }
    /**
     */
    async getSession(requestParameters, initOverrides) {
        const response = await this.getSessionRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getSessionRecordingsRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getSessionRecordings().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/sessions/{id}/recordings`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RecordingFromJSON));
    }
    /**
     */
    async getSessionRecordings(requestParameters, initOverrides) {
        const response = await this.getSessionRecordingsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getSessionsRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }
        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }
        if (requestParameters['activeOnly'] != null) {
            queryParameters['active_only'] = requestParameters['activeOnly'];
        }
        if (requestParameters['loggedInOnly'] != null) {
            queryParameters['logged_in_only'] = requestParameters['loggedInOnly'];
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/sessions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PaginatedResponseSessionSnapshotFromJSON(jsonValue));
    }
    /**
     */
    async getSessions(requestParameters = {}, initOverrides) {
        const response = await this.getSessionsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getSshKnownHostsRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/ssh/known-hosts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SSHKnownHostFromJSON));
    }
    /**
     */
    async getSshKnownHosts(initOverrides) {
        const response = await this.getSshKnownHostsRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getSshOwnKeysRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/ssh/own-keys`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SSHKeyFromJSON));
    }
    /**
     */
    async getSshOwnKeys(initOverrides) {
        const response = await this.getSshOwnKeysRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getSsoCredentialsRaw(requestParameters, initOverrides) {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError('userId', 'Required parameter "userId" was null or undefined when calling getSsoCredentials().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/users/{user_id}/credentials/sso`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExistingSsoCredentialFromJSON));
    }
    /**
     */
    async getSsoCredentials(requestParameters, initOverrides) {
        const response = await this.getSsoCredentialsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getTargetRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getTarget().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/targets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TargetFromJSON(jsonValue));
    }
    /**
     */
    async getTarget(requestParameters, initOverrides) {
        const response = await this.getTargetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getTargetRolesRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getTargetRoles().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/targets/{id}/roles`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleFromJSON));
    }
    /**
     */
    async getTargetRoles(requestParameters, initOverrides) {
        const response = await this.getTargetRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getTargetsRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/targets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TargetFromJSON));
    }
    /**
     */
    async getTargets(requestParameters = {}, initOverrides) {
        const response = await this.getTargetsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getTicketsRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/tickets`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TicketFromJSON));
    }
    /**
     */
    async getTickets(initOverrides) {
        const response = await this.getTicketsRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getUserRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getUser().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }
    /**
     */
    async getUser(requestParameters, initOverrides) {
        const response = await this.getUserRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getUserRolesRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getUserRoles().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/users/{id}/roles`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RoleFromJSON));
    }
    /**
     */
    async getUserRoles(requestParameters, initOverrides) {
        const response = await this.getUserRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async getUsersRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters['search'] != null) {
            queryParameters['search'] = requestParameters['search'];
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }
    /**
     */
    async getUsers(requestParameters = {}, initOverrides) {
        const response = await this.getUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async updateParametersRaw(requestParameters, initOverrides) {
        if (requestParameters['parameterUpdate'] == null) {
            throw new runtime.RequiredError('parameterUpdate', 'Required parameter "parameterUpdate" was null or undefined when calling updateParameters().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json; charset=utf-8';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/parameters`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ParameterUpdateToJSON(requestParameters['parameterUpdate']),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async updateParameters(requestParameters, initOverrides) {
        await this.updateParametersRaw(requestParameters, initOverrides);
    }
    /**
     */
    async updatePublicKeyCredentialRaw(requestParameters, initOverrides) {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError('userId', 'Required parameter "userId" was null or undefined when calling updatePublicKeyCredential().');
        }
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling updatePublicKeyCredential().');
        }
        if (requestParameters['newPublicKeyCredential'] == null) {
            throw new runtime.RequiredError('newPublicKeyCredential', 'Required parameter "newPublicKeyCredential" was null or undefined when calling updatePublicKeyCredential().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json; charset=utf-8';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/users/{user_id}/credentials/public-keys/{id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NewPublicKeyCredentialToJSON(requestParameters['newPublicKeyCredential']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ExistingPublicKeyCredentialFromJSON(jsonValue));
    }
    /**
     */
    async updatePublicKeyCredential(requestParameters, initOverrides) {
        const response = await this.updatePublicKeyCredentialRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async updateRoleRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling updateRole().');
        }
        if (requestParameters['roleDataRequest'] == null) {
            throw new runtime.RequiredError('roleDataRequest', 'Required parameter "roleDataRequest" was null or undefined when calling updateRole().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json; charset=utf-8';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/role/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RoleDataRequestToJSON(requestParameters['roleDataRequest']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => RoleFromJSON(jsonValue));
    }
    /**
     */
    async updateRole(requestParameters, initOverrides) {
        const response = await this.updateRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async updateSsoCredentialRaw(requestParameters, initOverrides) {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError('userId', 'Required parameter "userId" was null or undefined when calling updateSsoCredential().');
        }
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling updateSsoCredential().');
        }
        if (requestParameters['newSsoCredential'] == null) {
            throw new runtime.RequiredError('newSsoCredential', 'Required parameter "newSsoCredential" was null or undefined when calling updateSsoCredential().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json; charset=utf-8';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/users/{user_id}/credentials/sso/{id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['userId']))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: NewSsoCredentialToJSON(requestParameters['newSsoCredential']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ExistingSsoCredentialFromJSON(jsonValue));
    }
    /**
     */
    async updateSsoCredential(requestParameters, initOverrides) {
        const response = await this.updateSsoCredentialRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async updateTargetRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling updateTarget().');
        }
        if (requestParameters['targetDataRequest'] == null) {
            throw new runtime.RequiredError('targetDataRequest', 'Required parameter "targetDataRequest" was null or undefined when calling updateTarget().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json; charset=utf-8';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/targets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TargetDataRequestToJSON(requestParameters['targetDataRequest']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => TargetFromJSON(jsonValue));
    }
    /**
     */
    async updateTarget(requestParameters, initOverrides) {
        const response = await this.updateTargetRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     */
    async updateUserRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling updateUser().');
        }
        if (requestParameters['userDataRequest'] == null) {
            throw new runtime.RequiredError('userDataRequest', 'Required parameter "userDataRequest" was null or undefined when calling updateUser().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json; charset=utf-8';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["X-Warpgate-Token"] = await this.configuration.apiKey("X-Warpgate-Token"); // TokenSecurityScheme authentication
        }
        const response = await this.request({
            path: `/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserDataRequestToJSON(requestParameters['userDataRequest']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }
    /**
     */
    async updateUser(requestParameters, initOverrides) {
        const response = await this.updateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
