/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the GetLogsRequest interface.
 */
export function instanceOfGetLogsRequest(value) {
    return true;
}
export function GetLogsRequestFromJSON(json) {
    return GetLogsRequestFromJSONTyped(json, false);
}
export function GetLogsRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'before': json['before'] == null ? undefined : (new Date(json['before'])),
        'after': json['after'] == null ? undefined : (new Date(json['after'])),
        'limit': json['limit'] == null ? undefined : json['limit'],
        'sessionId': json['session_id'] == null ? undefined : json['session_id'],
        'username': json['username'] == null ? undefined : json['username'],
        'search': json['search'] == null ? undefined : json['search'],
    };
}
export function GetLogsRequestToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'before': value['before'] == null ? undefined : ((value['before']).toISOString()),
        'after': value['after'] == null ? undefined : ((value['after']).toISOString()),
        'limit': value['limit'],
        'session_id': value['sessionId'],
        'username': value['username'],
        'search': value['search'],
    };
}
