/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserRequireCredentialsPolicyFromJSON, UserRequireCredentialsPolicyToJSON, } from './UserRequireCredentialsPolicy';
/**
 * Check if a given object implements the UserDataRequest interface.
 */
export function instanceOfUserDataRequest(value) {
    if (!('username' in value) || value['username'] === undefined)
        return false;
    return true;
}
export function UserDataRequestFromJSON(json) {
    return UserDataRequestFromJSONTyped(json, false);
}
export function UserDataRequestFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'username': json['username'],
        'credentialPolicy': json['credential_policy'] == null ? undefined : UserRequireCredentialsPolicyFromJSON(json['credential_policy']),
    };
}
export function UserDataRequestToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'username': value['username'],
        'credential_policy': UserRequireCredentialsPolicyToJSON(value['credentialPolicy']),
    };
}
