/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the Ticket interface.
 */
export function instanceOfTicket(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('username' in value) || value['username'] === undefined)
        return false;
    if (!('target' in value) || value['target'] === undefined)
        return false;
    if (!('created' in value) || value['created'] === undefined)
        return false;
    return true;
}
export function TicketFromJSON(json) {
    return TicketFromJSONTyped(json, false);
}
export function TicketFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'username': json['username'],
        'target': json['target'],
        'usesLeft': json['uses_left'] == null ? undefined : json['uses_left'],
        'expiry': json['expiry'] == null ? undefined : (new Date(json['expiry'])),
        'created': (new Date(json['created'])),
    };
}
export function TicketToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'username': value['username'],
        'target': value['target'],
        'uses_left': value['usesLeft'],
        'expiry': value['expiry'] == null ? undefined : ((value['expiry']).toISOString()),
        'created': ((value['created']).toISOString()),
    };
}
