/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TargetFromJSON, TargetToJSON, } from './Target';
/**
 * Check if a given object implements the SessionSnapshot interface.
 */
export function instanceOfSessionSnapshot(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('started' in value) || value['started'] === undefined)
        return false;
    if (!('protocol' in value) || value['protocol'] === undefined)
        return false;
    return true;
}
export function SessionSnapshotFromJSON(json) {
    return SessionSnapshotFromJSONTyped(json, false);
}
export function SessionSnapshotFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'username': json['username'] == null ? undefined : json['username'],
        'target': json['target'] == null ? undefined : TargetFromJSON(json['target']),
        'started': (new Date(json['started'])),
        'ended': json['ended'] == null ? undefined : (new Date(json['ended'])),
        'ticketId': json['ticket_id'] == null ? undefined : json['ticket_id'],
        'protocol': json['protocol'],
    };
}
export function SessionSnapshotToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'username': value['username'],
        'target': TargetToJSON(value['target']),
        'started': ((value['started']).toISOString()),
        'ended': value['ended'] == null ? undefined : ((value['ended']).toISOString()),
        'ticket_id': value['ticketId'],
        'protocol': value['protocol'],
    };
}
