/* tslint:disable */
/* eslint-disable */
/**
 * Warpgate Web Admin
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.13.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ParameterValues interface.
 */
export function instanceOfParameterValues(value) {
    if (!('allowOwnCredentialManagement' in value) || value['allowOwnCredentialManagement'] === undefined)
        return false;
    return true;
}
export function ParameterValuesFromJSON(json) {
    return ParameterValuesFromJSONTyped(json, false);
}
export function ParameterValuesFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'allowOwnCredentialManagement': json['allow_own_credential_management'],
    };
}
export function ParameterValuesToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'allow_own_credential_management': value['allowOwnCredentialManagement'],
    };
}
